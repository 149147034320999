import React, { Component } from "react";
import { UserService } from "./../service";
import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Api } from "../../../config/api";
import Swal from "sweetalert2";

class ListUser extends Component {
	constructor() {
		super();
		this.state = {
			loading: true,
			users: [],
			search: "",
			serach_result: []
		};
		this.onUserChange = this.onUserChange.bind(this);
		this.verifyUsers = this.verifyUsers.bind(this);
		this.unVerifyUsers = this.unVerifyUsers.bind(this);
		this.sendForgetPasswordEmail = this.sendForgetPasswordEmail.bind(this);
		this.deleteUser = this.deleteUser.bind(this);
	}

	async deleteUser(value) {
		let that = this;
		let result = await Swal.fire({
			title: "Are you sure?",
			text: "Do you want to delete this user?",
			icon: "info",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, Delete!"
		}).then(result => {
			return result;
		});

		if (result.value) {
			await that.setState({ loading: true });

			await Api.get("/auth/deleteUser/" + value)
				.then(function(response) {
					that.setState({ message: response.data });

					Swal.fire({
						title: "User Deleted Success!",
						text: "User has been deleted successfully!",
						icon: "success",
						confirmButtonText: "OK"
					});
				})
				.catch(function(error) {
					Swal.fire({
						title: "User Delete Error!",
						text: "User has been not been deleted!!",
						icon: "error",
						confirmButtonText: "OK"
					});
				});

			let data = await UserService.showUsers(this.props.token);
			await that.setState({ users: data ? data : [] });
			await that.setState({ serach_result: data ? data : [] });

			await that.setState({ loading: false });
		}
	}

	async resetotpUser(value) {
		let that = this;
		let result = await Swal.fire({
			title: "Are you sure?",
			text: "Do you want to resetOTP of this user?",
			icon: "info",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, ResetOTP!"
		}).then(result => {
			return result;
		});

		if (result.value) {
			await that.setState({ loading: true });

			await Api.post("/auth/reset-two-fa/" + value)
				.then(function(response) {
					that.setState({ message: response.data });

					Swal.fire({
						title: "User ResetOTP Success!",
						text: "User has set OTP successfully!",
						icon: "success",
						confirmButtonText: "OK"
					});
				})
				.catch(function(error) {
					Swal.fire({
						title: "User ResetOTP Error!",
						text: "User has not set OTP!!",
						icon: "error",
						confirmButtonText: "OK"
					});
				});

			let data = await UserService.showUsers(this.props.token);
			await that.setState({ users: data ? data : [] });
			await that.setState({ serach_result: data ? data : [] });

			await that.setState({ loading: false });
		}
	}

	async sendForgetPasswordEmail(value) {
		await this.setState({ loading: true });

		let result = await Swal.fire({
			title: "Are you sure?",
			text: "Do you want to send the password reset link to this user?",
			icon: "info",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, send it!"
		}).then(result => {
			return result;
		});

		let that = this;
		let params = {
			email: value
		};

		if (result.value) {
			await Api.post("/password/email", params)
				.then(function(response) {
					that.setState({ message: response.data });

					Swal.fire({
						title: "Password Resest!",
						text: "Password reset e-mail has been sent! Please check!!",
						icon: "success",
						confirmButtonText: "OK"
					});
				})
				.catch(function(error) {
					Swal.fire({
						title: "Password Reset!",
						text: "Password reset e-mail has not been sent!!",
						icon: "error",
						confirmButtonText: "OK"
					});
				});
		}
		await this.setState({ loading: false });
	}

	async unVerifyUsers(value) {
		let redirect = this;

		let result = await Swal.fire({
			title: "Are you sure?",
			text: "Do you want to block this user?",
			icon: "info",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, Block!"
		}).then(result => {
			return result;
		});

		if (result.value) {
			Api.get("unVerifyEmail/" + value).then(async function(response) {
				let data = await UserService.showUsers(redirect.props.token);
				await redirect.setState({ users: data ? data : [] });
				await redirect.setState({ serach_result: data ? data : [] });
			});
		}
	}

	async verifyUsers(value) {
		let redirect = this;

		let result = await Swal.fire({
			title: "Are you sure?",
			text: "Do you want to verify this user?",
			icon: "info",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Yes, verify!"
		}).then(result => {
			return result;
		});

		if (result.value) {
			Api.get("verifyEmail/" + value).then(async function(response) {
				let data = await UserService.showUsers(redirect.props.token);
				await redirect.setState({ users: data ? data : [] });
				await redirect.setState({ serach_result: data ? data : [] });
			});
		}
	}

	async componentDidMount() {
		let redirect = this;

		let data = await UserService.showUsers(this.props.token);
		await redirect.setState({ users: data ? data : [] });
		await redirect.setState({ serach_result: data ? data : [] });

		redirect.setState({ loading: false });
	}

	onUserChange(name, value) {
		let redirect = this;
		switch (name) {
			case "user":
				redirect.setState({ search: value });
				let user = redirect.state.users;
				user = user.filter(
					item =>
						item.name.toLowerCase().includes(value) ||
						item.email.toLowerCase().includes(value)
				);
				redirect.setState({ serach_result: user });

				break;

			default:
				break;
		}
	}

	render() {
		if (this.state.loading) {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">
											Show All Users
										</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<div className="row">
															<div className="col"></div>
															<div className="col">
																<div className="text-center">
																	<Loader
																		type="Rings"
																		color="#00BFFF"
																		height={100}
																		width={100}
																	/>
																	<div className="text-center client-roboto-condensed">
																		LOADING...
																	</div>
																</div>
															</div>
															<div className="col"></div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<div>
					<div
						className="content"
						style={{
							minHeight: 500
						}}
					>
						<div className="row">
							<div className="col-sm-12">
								<div className="card">
									<div className="card-header">
										<strong className="card-title">
											Show All Users
										</strong>
									</div>
									<div className="card-body">
										<div className="card-content">
											<div className="todo-list">
												<div className="tdl-holder">
													<div className="tdl-content">
														<div className="row">
															<div className="col"></div>
															<div className="col">
																<div className="form-group">
																	<input
																		className="form-control"
																		placeholder="Filter Users"
																		name="user"
																		value={this.state.search}
																		onChange={e =>
																			this.onUserChange(
																				"user",
																				e.target.value
																			)
																		}
																	/>
																</div>
															</div>
														</div>
														<ol>
															{this.state.serach_result.map(
																(user, i) => (
																	<li key={i}>
																		<label
																			style={{
																				color: "black"
																			}}
																		>
																			{user.name +
																				"  [ " +
																				user.email +
																				" ]  [ " +
																				user.role_name +
																				" ]"}
																			<Link
																				onClick={() =>
																					this.deleteUser(
																						user.id
																					)
																				}
																				to="#"
																				style={{
																					marginLeft:
																						"10px"
																				}}
																				title="Delete User"
																			>
																				<i className="fa fa-lg ml-3 text-dark fa-times"></i>
																			</Link>
																			<Link
																				onClick={() =>
																					this.resetotpUser(
																						user.id
																					)
																				}
																				style={{
																					marginLeft:
																						"10px"
																				}}
																				title="Reset OTP"
																			>
																				<i className="fa fa-lg ml-3 text-dark fa-rotate-right"></i>
																			</Link>
																			<Link
																				to={
																					"/update-user/" +
																					user.id
																				}
																				style={{
																					marginLeft:
																						"10px"
																				}}
																				title="Update User"
																			>
																				<i className="fa fa-lg ml-3 text-dark fa-pencil"></i>
																			</Link>
																			{user.role_name
																				.toLowerCase()
																				.includes(
																					"admin"
																				) ? null : (
																				<Link
																					to={
																						"/update-user-hierarchy/" +
																						user.id
																					}
																					className="ml-3"
																					title="User Hierarchy"
																				>
																					<i className="fas fa-sitemap text-dark"></i>
																				</Link>
																			)}
																			{user.email_verified_at ===
																			null ? (
																				<>
																					<Link
																						onClick={() =>
																							this.verifyUsers(
																								user.id
																							)
																						}
																						to="#"
																						title="Verify User"
																					>
																						<i className="fas fa-lg text-dark fa-user-check"></i>
																					</Link>
																				</>
																			) : (
																				<>
																					<Link
																						onClick={() =>
																							this.sendForgetPasswordEmail(
																								user.email
																							)
																						}
																						to="#"
																						title="Password Reset"
																					>
																						<i className="fa fa-lg text-dark fa-key"></i>
																					</Link>
																					<Link
																						onClick={() =>
																							this.unVerifyUsers(
																								user.id
																							)
																						}
																						to="#"
																						className="mx-3"
																						title="Block User"
																					>
																						<i
																							className="fas fa-user-check"
																							id="container"
																						>
																							<i
																								className="fa fa-ban"
																								id="nested"
																							></i>
																						</i>
																					</Link>
																				</>
																			)}
																		</label>
																	</li>
																)
															)}
														</ol>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			);
		}
	}
}

const mapStateToProps = state => {
	return {
		isAuthenticated: state.loginReducer.isAuthenticated,
		token: state.loginReducer.token,
		user: state.loginReducer.user.user
	};
};

export default connect(mapStateToProps, {})(ListUser);
