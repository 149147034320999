import React, { Component } from "react";
import { connect } from "react-redux";
import { login } from "./../action";
import { css } from "@emotion/react";
import { PulseLoader } from "react-spinners";
import { clean_login_message } from "./../action";
import Swal from "sweetalert2";
import config from "../../config/config";

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			email: "",
			password: "",
			langauge_id: "",
			countries: [],
			selected_country: ""
		};
		this.onTextChange = this.onTextChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	onTextChange(name, value) {
		let redirect = this;
		switch (name) {
			case "email":
				redirect.setState({ email: value });
				break;

			case "password":
				redirect.setState({ password: value });
				break;

			default:
				break;
		}
	}

	async onSubmit(e) {
		e.preventDefault();

		let params = {
			email: this.state.email,
			password: this.state.password
		};

		await this.props.login(params);
	}

	async componentDidUpdate() {
		if (this.props.message.toLowerCase().includes("login faliure")) {
			Swal.fire({
				title: "Login Error!",
				text:
					"E-mail or Password incorrect. Please give in the correct credentials!!!",
				icon: "info",
				confirmButtonText: "OK"
			});
			await this.props.clean_login_message();
		}
	}

	async componentDidMount() {
		await this.setState({ loading: false });
		// window.location.href = `${config.CLIENT_URL}auth/login`;
	}

	render() {
		const override = css`
			display: block;
			margin: 0 auto;
			border-color: red;
		`;
		if (this.props.loading || this.state.loading) {
			return (
				<div className="sweet-loading">
					<div className="container loading-container">
						<div className="row m-5 p-5">
							<PulseLoader
								css={override}
								sizeUnit={"px"}
								size={24}
								color={"#B9373D"}
							/>
						</div>
					</div>
				</div>
			);
		} else {
			return (
				<>
					<div className="">
						<p>Redirect to Login Page!!!</p>
					</div>
				</>
				// <div>
				// 	<div className="mt-4 mb-4 roboto-condensed container">
				// 		<div className="row">
				// 			<div className="col"></div>
				// 			<div className="col-8">
				// 				<div className="text-center">
				// 					<div className="content-section introduction my-3">
				// 						<div className="feature-intro">
				// 							<h4 className="client-roboto-condensed font-weight-bold">
				// 								Login | Reesink UK CPQ
				// 							</h4>
				// 						</div>
				// 					</div>
				// 					<div className="row my-5">
				// 						<div className="col"></div>
				// 						<div className="col-8">
				// 							<form>
				// 								<div className="form-group">
				// 									<label className="client-roboto-condensed ">
				// 										E-mail Address
				// 									</label>
				// 									<Input
				// 										type="text"
				// 										value={this.state.email}
				// 										onChange={e =>
				// 											this.onTextChange(
				// 												"email",
				// 												e.target.value
				// 											)
				// 										}
				// 										className="form-control"
				// 										placeholder="Enter E-mail"
				// 									/>
				// 								</div>
				// 								<div className="form-group">
				// 									<label className="client-roboto-condensed ">
				// 										Password
				// 									</label>
				// 									<Input
				// 										type="password"
				// 										value={this.state.password}
				// 										onChange={e =>
				// 											this.onTextChange(
				// 												"password",
				// 												e.target.value
				// 											)
				// 										}
				// 										className="form-control client-roboto-condensed "
				// 										placeholder="Enter Password"
				// 									/>
				// 								</div>
				// 								<div className="text-center mb-4">
				// 									<Link
				// 										className="red-link client-roboto-condensed "
				// 										to="/forgot-password"
				// 									>
				// 										Forgot Password?
				// 									</Link>
				// 								</div>
				// 								<div>
				// 									<button
				// 										type="submit"
				// 										onClick={this.onSubmit}
				// 										className="client-roboto-condensed client-login m-2 px-5"
				// 									>
				// 										Login
				// 									</button>
				// 								</div>
				// 							</form>
				// 						</div>
				// 						<div className="col"></div>
				// 					</div>
				// 				</div>
				// 			</div>
				// 			<div className="col"></div>
				// 		</div>
				// 	</div>
				// </div>
			);
		}
	}
}
const mapStateToProps = state => {
	return {
		loading: state.loginReducer.loading,
		message: state.loginReducer.message
	};
};

export default connect(mapStateToProps, {
	login,
	clean_login_message
})(Login);
